import './Apps.css';
import TapTempoApp from './TapTempo';

const Apps = () => {
    return <div className="container">
        {/* <h1>Apps</h1> */}
        <TapTempoApp />
    </div>
}

export default Apps;