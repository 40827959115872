import React, { useState, useEffect } from 'react';
import './TapTempo.css';

const TapTempoApp = () => {
    const [times, setTimes] = useState([]);
    const [bpm, setBpm] = useState(0);
    const [hue, setHue] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const [clickTimeoutId, setClickTimeoutId] = useState(null);
    const [isPaused, setIsPaused] = useState(false);
    const [clicks, setClicks] = useState(0);
  
    const handleClick = () => {
      setClicks(prevClicks => prevClicks + 1);
      setTimes(prevTimes => [...prevTimes.slice(-3), Date.now()]);
      setHue(prevHue => (prevHue + 37) % 360);
    };

    useEffect(() => {
      if (times.length > 1) {
        const intervals = times.slice(1).map((time, i) => time - times[i]);
        const avgInterval = intervals.reduce((a, b) => a + b) / intervals.length;
        setBpm(Math.round(60000 / avgInterval));
  
        if (intervalId) {
          clearInterval(intervalId);
        }

        const id = setInterval(() => {
          if (!isPaused) {
            setHue(prevHue => (prevHue + 37) % 360);
          }
        }, avgInterval);
  
        setIntervalId(id);
      }
    }, [times, intervalId, isPaused]);

    useEffect(() => {
      if (clickTimeoutId) {
        clearTimeout(clickTimeoutId);
      }
      if (clicks > 0) {
        setClickTimeoutId(setTimeout(() => {
          setClicks(0);
          setTimes([]);
        }, 2000));
      }
    }, [clickTimeoutId, clicks]);

    useEffect(() => {
      if (clicks >= 3) {
        setIsPaused(false);
        setClicks(0);
      }
    }, [clicks]);

    const handlePausePlayClick = () => {
      setIsPaused(prevIsPaused => !prevIsPaused);
    }

    // const handleBpmChange = (event) => {
    //   const newBpm = Number(event.target.value);
    //   setBpm(newBpm);
    //   if (newBpm > 0) {
    //     clearInterval(intervalId);
    //     setIntervalId(setInterval(() => {
    //       if (!isPaused) {
    //         setHue(prevHue => (prevHue + 37) % 360);
    //       }
    //     }, 60000 / newBpm));
    //   }
    // }

    useEffect(() => {
      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
        if (clickTimeoutId) {
          clearTimeout(clickTimeoutId);
        }
      }
    }, [intervalId, clickTimeoutId]);

  return (
    <div className='tapTempoWrapper'>
      <p className='tapTempoTitle'>TAP</p>
      <div className='tapTempoDiv'
        onClick={handleClick}
        style={{
          background: `hsl(${hue}, 50%, 70%)`, // Use hue state variable here
          boxShadow: `0 0 1px 5px hsla(${hue}, 50%, 70%, 1),
                      0 0 3px 10px hsla(${hue}, 50%, 70%, .9),
                      0 0 3px 15px hsla(${hue}, 50%, 70%, .8),
                      0 0 3px 20px hsla(${hue}, 50%, 70%, .7)`
        }}
      >
        {/* Tap Here */}
        
      </div>
      <p className='bpmDisplay'>BPM: {bpm}
      </p>
      <button className='pauseButton' onClick={handlePausePlayClick}
      style={{
        background: `hsl(${hue}, 20%, 70%)`
      }}
      >{isPaused ? <i class="fa fa-play" aria-hidden="true"></i>
      : <i class="fa fa-pause" aria-hidden="true"></i>}</button>
    </div>
  );
}

export default TapTempoApp;

