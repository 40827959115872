import './Form.css'
import {useState} from 'react'
import { useNavigate } from 'react-router-dom';

function Form() {
    const [formState, setFormState] = useState({
      name: "",
      email: "",
      message: "",
    });

    const [errorState, setErrorState ] = useState({
      name: null,
      email: null,
      message: null,
    });
  
    const handleInputChange = (event) => {
      setFormState({
        ...formState,
        [event.target.name]: event.target.value,
      });
      if (validateInput(event.target.name, event.target.value)) {
        event.target.classList.add('filled');
      } else {
        event.target.classList.remove('filled');
      }
      if (event.target.value && !validateInput(event.target.name, event.target.value)) {
        event.target.classList.add('invalid');
    } else {
        event.target.classList.remove('invalid');
    }
    };

    const navigate = useNavigate();

    const validateInput = (name, value) => {
      switch(name) {
        case 'email':
          // Regular expression for email validation
          const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
          return emailRegex.test(value);
        case 'name':
          // Check if name is not empty and does not contain numbers
          const nameRegex = /^[A-Za-z0-9-' ]+$/;
          return nameRegex.test(value);
        default:
          // By default just checks if value is not empty
          return !!value;
      }
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
    
      let hasError = false;
    
      const errors = {
        name: validateInput("name", formState.name) ? null : "Invalid name",
        email: validateInput("email", formState.email) ? null : "Invalid email",
        message: validateInput("message", formState.message) ? null : "Message cannot be empty",
      };
    
      for (let error in errors) {
        if (errors[error]) hasError = true;
      }
    
      if (hasError) {
        setErrorState(errors);
        return; // if there are errors, we stop here
      } 
    
      console.log(formState);
    
      const formData = new FormData(event.target);
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
      .then(() => {
        console.log("Form successfully submitted");
        navigate('/thank-you');
      })
      .catch((error) => {
        console.error(error);
      });
    };
  
    return (
        <div className='form-wrapper'>
            <form className="contact-form" name="contact v1" method="POST" data-netlify="true" onSubmit={handleSubmit}>
                <input type="hidden" name="form-name" value="contact v1" />
                <p>
                <label>
                    {/* Name:  */}
                    <input type="text" name="name" placeholder='Name (required)' onChange={handleInputChange} />
                    {errorState.name && <span className="error">{errorState.name}</span>}
                </label>
                </p>
                <p>
                <label>
                    {/* Email:  */}
                    <input type="email" name="email" placeholder='Email (required)' onChange={handleInputChange} />
                    {errorState.email && <span className="error">{errorState.email}</span>}
                </label>
                </p>
                <p>
                <label>
                    {/* Message:  */}
                    <textarea name="message" placeholder='Let me know how I might help you here - feel free to include links to your project.' onChange={handleInputChange} />
                </label>
                {errorState.message && <span className="error">{errorState.message}</span>}
                </p>
                <p>
                <button type="submit">submit</button>
                
                </p>
            </form>
        </div>
    );
  }
  
  export default Form;