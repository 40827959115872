import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { useLayoutEffect, useState } from 'react';

const ContactCanvas = () => {
  const mountRef = useRef(null);
  const [dimension, setDimension] = useState(null);  // State to store the dimension

  useLayoutEffect(() => { // This hook fires synchronously after all DOM mutations
    if (mountRef.current) {
      const availableHeight = mountRef.current.clientHeight;
      const availableWidth = mountRef.current.clientWidth;
      const size = Math.min(availableHeight, availableWidth); // take the smaller dimension
      setDimension(size); // set it to state
    }
  }, []);

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    camera.position.z = 5;


    //MAIN LIGHT
    const ambient = new THREE.AmbientLight(0x404040, 1.2); // soft white light
    scene.add(ambient);

    //SHAPE
    const geometry = new THREE.IcosahedronGeometry(1, 0);


    //MATERIAL
    const material = new THREE.MeshPhongMaterial({ color: new THREE.Color('#ccc') });

    //OBJECT
    const cube = new THREE.Mesh(geometry, material);
    cube.castShadow = true;
    cube.receiveShadow = true;
    scene.add(cube);
    scene.scale.set(3, 3, 3);

    //POINT LIGHTS
    const redLight = new THREE.PointLight(0xfffffc, 5, 50);
    redLight.position.set(0, 5, -.3);
    redLight.castShadow = true;
    scene.add(redLight);

    // Function to update light color
    // const updateLightColor = (light, elapsedTime) => {
    //   // Define the range for hue between blue and green
    //   const minHue = 0.33; // closer to green
    //   const maxHue = 0.66; // closer to blue
    
    //   // Determine the range width
    //   const hueRange = maxHue - minHue;
    
    //   // Calculate the hue value within the restricted range over time
    //   // The % 1 ensures the value wraps around for a continuous cycle
    //   const hue = (minHue + (elapsedTime * 0.1) % hueRange);
    
    //   // Set the saturation and lightness to get a soft, pastel-like effect
    //   const saturation = 0.3;
    //   const luminance = 0.3;
    
    //   // Set the light color using HSL
    //   light.color.setHSL(hue, saturation, luminance);
    // };

    const underLight = new THREE.PointLight(0xffffff, 3, 50);
    underLight.position.set(0, -5, .5);
    underLight.castShadow = true;
    scene.add(underLight);

    const blueLight = new THREE.PointLight(0xffffff, 3, 50);
    blueLight.position.set(-10, 0, 0);
    blueLight.castShadow = true;
    scene.add(blueLight);

    const yellowLight = new THREE.PointLight(0xffffff, 2, 50);
    yellowLight.position.set(10, 0, 0);
    yellowLight.castShadow = true;
    scene.add(yellowLight);

    const greenLight = new THREE.PointLight(0xffffff, 2, 50);
    greenLight.position.set( 5, -5, 0);
    greenLight.castShadow = true;
    scene.add(greenLight);


    //RENDERER
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.shadowMap.enabled = true;
    renderer.setClearColor(0x000000, 0); // Light gray background
    renderer.setSize(dimension, dimension);
    mountRef.current.appendChild(renderer.domElement);

    const animate = (time) => {
      requestAnimationFrame(animate);

      //ROTATE lights
    //   redLight.rotation.y += -0.01;

      //ROTATE shape
      cube.rotation.x += -0.01;
      cube.rotation.y += 0.01;
      cube.rotation.z += -0.01;

       // Calculate elapsed time
      // const elapsedTime = time * 0.001; // convert time to seconds

      // Update the color of the light
      // updateLightColor(redLight, elapsedTime);



      renderer.render(scene, camera);
    };

    const handleResize = () => {
        const size = mountRef.current.clientHeight; // since it's a square, width is enough
        camera.aspect = 1; // keep it square
        camera.updateProjectionMatrix();
        renderer.setSize(size, size);
      };

    window.addEventListener('resize', handleResize);

    handleResize();
    animate();

    return () => {
        window.removeEventListener('resize', handleResize);
        renderer.domElement.remove();  // This will remove the canvas element
    };

  }, [dimension]);

  return ( <div className="contact-canvas" 
  ref={mountRef} 
/> );
};

export default ContactCanvas;
