import './Contact.css';
import Form from './Form';
import ContactCanvas from './CntCanvas';

const Contact = () => {

    
    
    return ( 
    <div className="container">
        {/* <h1>Contact</h1> */}
    
        <div class="grid-container">

            <div className='top-element'>
                <p className='sayHello'>
                    say hello, ask a question, get details on my work
                    <a href="https://open.spotify.com/playlist/1N1bc7bSgzuM1HhrylvLGM?si=6bb7ddbb2d4c4994"> mixing,</a>
                    <a href="https://open.spotify.com/playlist/1QqxRfr04JTshvrrsHFgdg?si=0447c91d6ca84c54"> mastering, </a>
                    engineering, 
                    <a href="/"> developing/designing </a>
                    or anything else.
                </p>
            </div>

            <div className='middle'>
                <Form />
            </div>

            <div className='bottom-element'>
                <div className='canvas-wrapper'>
                <ContactCanvas />
                {/* <p className="thankYou">
                    <p>
                        thank you
                    </p>
                </p> */}
                </div>
            </div>

        </div>
        
    </div>

    );
}

export default Contact;