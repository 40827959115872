import './Contact.css';
import ContactCanvas from './CntCanvas';
import './thankyou.css';




const ThankYou = () => {
    return (
        <div className="mobile-container">
        <div className='canvas-wrapperTY'>
            <ContactCanvas />
            <div className="thankYouTY">
                <span>
                    thank you
                </span>
            </div>
        </div>
        </div>
    );
}

export default ThankYou;