import { useLocation } from 'react-router';
import './About.css';
// import AboutCanvas from './AbtCanvas';
import React, { useEffect } from 'react';
import Dropdown from './Dropdown';

const About = () => {
  const location = useLocation();

  useEffect(() => {
    const contentContainer = document.querySelector(".content_container");
    const circleBackground = document.querySelector(".circle-background");
    const nameContainer = document.querySelector(".nameContainer");
    const myNameLogoBig = document.querySelector(".nameContainerBig");

    // Check if the prop was passed. If so, set opacity to 1 immediately.
    if (location.state && location.state.showContentImmediately) {
      contentContainer.style.opacity = 1;
    } else {

      //When click is outside, remove active classes
      const handleClickOutside = (event) => {
        // Check if the click was outside the contentContainer
        if (contentContainer && !contentContainer.contains(event.target)) {
          // Check if the clicked element is one of the nested elements you want to exclude
          if (
            !event.target.classList.contains('hello_container') &&
            !event.target.classList.contains('audioEngineer_container') &&
            !event.target.classList.contains('musician_container') &&
            !event.target.classList.contains('developer_container')
          ) {
            // Deactivate the contentContainer, for example, by setting its opacity to 0
            contentContainer.classList.remove('active');
            circleBackground.classList.remove('active');
            nameContainer.classList.remove('active');
            myNameLogoBig.classList.remove('active');
          }
        }
      }

      // Attach the event listener
      document.addEventListener("mousedown", handleClickOutside);

      //When circle bg is clicked, add active class on objects
      const handleCircleClick = () => {
        // Toggle the 'active' classes
        if (circleBackground.classList.contains('active')) {
          contentContainer.classList.remove('active');
          circleBackground.classList.remove('active');
          nameContainer.classList.remove('active');
          myNameLogoBig.classList.remove('active');
        } else {
          contentContainer.classList.add('active');
          circleBackground.classList.add('active');
          nameContainer.classList.add('active');
          myNameLogoBig.classList.add('active');
        }
      }

      // Attach the event listener to the circle-background and nameContainer

      circleBackground.addEventListener("click", handleCircleClick);

      nameContainer.addEventListener("click", handleCircleClick);

        // Scroll up event listener to remove the active class
      const handleScroll = () => {

          contentContainer.classList.remove('active');
          circleBackground.classList.remove('active');
          nameContainer.classList.remove('active');
      };

      // Scroll up event listener
      document.addEventListener("scroll", handleScroll);

  

      // Cleanup
      return () => {

        document.removeEventListener("scroll", handleScroll);
        document.removeEventListener("mousedown", handleClickOutside);
        circleBackground.removeEventListener("click", handleCircleClick);
        nameContainer.removeEventListener("click", handleCircleClick);
      };
    }

  }, [location.state]);

  

  return (
      
      <div className="about_container" 
      //import an svg
      // style={{ backgroundImage: 'url(./AboutImages/profile67left.svg)',
      // backgroundPosition: 'left center', 
      // backgroundSize: 'contain',
      // backgroundRepeat: 'no-repeat'}}

      >

        <div className={'circle-background'}>
        </div>
        
        {/* <AboutCanvas /> */}

          {/* small DA */}
          <div className='nameContainer'>
            <p className='myNameLogo'>
              d.a.
            </p>
          </div>

          {/* large DA */}
          {/* <div className='nameContainerBig'>
            <p className='myNameLogoBig'>
              <span className='clickableText'>d.a.</span>
            </p>
          </div> */}

        <div className='content_container'>
          

          {/* <p>Hello, I am</p> */}

          {/* <div className='hello_container'>
              <a href="/contact" className="hello"><p>D</p>alton <p>A</p>llison</a>
          </div> */}

          <div className='spacerDiv'></div>

          <p>My work as an</p>

          <div className='spacerDiv'></div>

          <div className='audioEngineer_container'>
            <Dropdown title="audio engineer" className='audioEngineer'>
            <a href="https://open.spotify.com/playlist/1N1bc7bSgzuM1HhrylvLGM?si=6bb7ddbb2d4c4994">mixedBy</a>
              
              <a href="https://open.spotify.com/playlist/1QqxRfr04JTshvrrsHFgdg?si=0447c91d6ca84c54">masteredBy</a>
              <br></br>
              <a href="/credits">credits</a>
            </Dropdown>
          </div>
          <div className='spacerDiv'></div>
          <div className='musician_container'>
            <Dropdown title="musician" className='musician'>
            <a href="https://music.apple.com/us/artist/daallher/1435144773" style={{ fontStyle: 'italic'}}>daallher</a>
              <a href="http://www.postanimal.us">Post Animal</a>
            </Dropdown>
          </div>
          <p>&</p>
          <p>creative</p>
          <div className='developer_container'>
            <Dropdown title="developer" className='developer'>
              <a href="http://www.daltonallison.com/">this site</a>
            </Dropdown>
          </div>

          <div className='abt-canvas-wrapper'>
            {/* <AboutCanvas /> */}
            {/* <div className="DA-LOGO-div" style={{ backgroundImage: 'url(./AboutImages/DA_icon_white.svg)', backgroundPosition: 'center', backgroundSize: 'auto', backgroundRepeat: 'no-repeat' }}></div> */}
          </div>
          
        </div>

        <div className="spacer"></div>

      </div>
  );
  
};

export default About;
