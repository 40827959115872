
import React from 'react';
import AccordionMenu from './Components/Navbar';
import Footer from './Components/Footer';
import About from './Components/About/About';
import Portfolio from './Components/Portfolio/Portfolio';
import Apps from './Components/Apps/Apps';
import Contact from './Components/Contact/Contact';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ThankYou from './Components/Contact/thank-you';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {

  return (
    <>
      <AccordionMenu />

      <Footer />

      <ScrollToTop />

      <Routes>
        <Route path="/" element={<About />} />
        <Route path="/credits" element={<Portfolio />} />
        <Route path="/apps" element={<Apps />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/thank-you" element={<ThankYou />} />
      </Routes>
    </>
  );
}

export default App;
