import React, { useState, useEffect } from 'react';
import './portfolio2.css';
import portfolioItems from './portfolioItems';

const Portfolio = () => {
    const [activeItem, setActiveItem] = useState(null);
    const [hasClicked, setHasClicked] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const handleItemClick = (index) => {
        if (activeItem === portfolioItems[index]) {
            setActiveItem(null);
            setHasClicked(false);
        } else {
            setTimeout(() => {
            setActiveItem(portfolioItems[index]);
            setHasClicked(true);
            }, 300);
        }
    };

    const handleItemMouseEnter = (index) => {
        if (hasClicked) {
            setActiveItem(portfolioItems[index]);
        }
    };

    const handleItemMouseLeave = () => {
        if (hasClicked && activeItem) {
            setActiveItem(null);
        }
    };

     // Adjusting font size for album titles
    //  const titles = document.querySelectorAll('.albumTitle');
    //  titles.forEach(title => {
    //      if (title.textContent.length > 20) {
    //          title.style.fontSize = '1.5em';
    //      }
    //  });

    useEffect(() => {

        const imagesToLoad = portfolioItems.map((item) => {
            const image = new Image();
            image.src = item.src;
            return image;
          });
        
          Promise.all(imagesToLoad.map((image) => new Promise((resolve) => image.onload = resolve)))
            .then(() => {
              setIsLoading(false);
            });
        
        // Adjusting font size for album titles
        const titles = document.querySelectorAll('.albumTitle');
        titles.forEach(title => {
            if (title.textContent.length > 20) {
                title.style.fontSize = '1.5em';
            }
        });

        titles.forEach(title => {
            if (title.textContent.length > 40) {
                title.style.fontSize = '1.2em';
            }
        });

        const credits = document.querySelectorAll('.releaseCredit');
        credits.forEach(credit => {
            if (credit.textContent.length > 20) {
                credit.style.fontSize = '20px';
            }
        });

        credits.forEach(credit => {
            if (credit.textContent.length > 40) {
                credit.style.fontSize = '16px';
            }
        });
    

        const handleOutsideClick = (event) => {
            if (
                !event.target.closest('.portfolio_modal') && 
                !event.target.closest('.portfolio_item_img') &&
                !event.target.closest('.accordion_container') &&
                !event.target.closest('.footer')
            ) {
                setActiveItem(null);
                setHasClicked(false);
            }
        };
        const handleScroll = () => {
            if (!hasClicked) return;
    
            const centerY = window.innerHeight / 2;
    
            let closestItem = null;
            let closestDistance = Infinity;
    
            portfolioItems.forEach((item, index) => {
                const imgElement = document.querySelector(`.portfolio_item_img:nth-child(${index + 1})`);
                if (imgElement) {
                    const rect = imgElement.getBoundingClientRect();
                    const imgCenterY = rect.top + rect.height / 2;
                    const distance = Math.abs(centerY - imgCenterY);
    
                    if (distance < closestDistance) {
                        closestDistance = distance;
                        closestItem = item;
                    }
                }
            });
    
            if (closestItem && activeItem !== closestItem) {
                setActiveItem(closestItem);
            }
        };
    
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            window.removeEventListener('scroll', handleScroll);
        };

        

       
    }, [activeItem, hasClicked]);

    const loadingElement = (
        <div className="loading">
          <p>loading... :)</p>
        </div>
      );

    return (
        <div className="portfolio_container">
            {isLoading ? (
                loadingElement
                ) : (
            <div className="portfolio_gallery">
                {portfolioItems.map((item, index) => (
                    <div 
                        className={`portfolio_item ${activeItem === item ? 'active' : ''}`} 
                        key={index} 
                        onMouseEnter={() => handleItemMouseEnter(index)} 
                        onMouseLeave={handleItemMouseLeave} 
                        onClick={() => handleItemClick(index)}
                    >
                        <img 
                            className='portfolio_item_img'
                            src={item.src} 
                            alt={item.title} 
                        />
                        {activeItem === item && (
                            <div 
                                className={`portfolio_modal ${activeItem === item ? 'active' : ''}`}
                            >
                                <h2 className='albumTitle'>{activeItem.title}</h2>
                                <p className="albumBy">by</p>
                                <h3 className='artistName'>{activeItem.artist}</h3>
                               
                                <p className='recordLabel'>{activeItem.label}</p>
                                <p className='releaseDate'>released on {activeItem.releaseDate}</p>
                                
                                <p className='releaseCredit'>{activeItem.credit}</p>
                                
                                <a href={activeItem.spotify} className="social-icon"><i className="fab fa-spotify"></i></a>
                            </div>
                        )}
                    </div>
                ))}
            </div>
                )}
        </div>
    );
}

export default Portfolio;